import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Web3 from "web3";
import NftCard from './components/nftcard';
import { InputNumber } from 'rsuite';
import { Input, InputGroup, MaskedInput } from 'rsuite';
import "rsuite/dist/rsuite.min.css"

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #3B04A0;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 4px 0px -2px rgba(10, 10, 10, 0.4);
  -webkit-box-shadow: 0px 5px 0px -2px rgba(10, 10, 10, 0.5);
  -moz-box-shadow: 0px 5px 0px -2px rgba(10, 10, 10, 0.5);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: #3B04A0;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 15px 0px -2px rgba(200, 200, 200, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(200, 200, 200, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(200, 200, 200, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;


export const StyledImg = styled.img`
  width: 100px;
  height: 80px;
  @media (min-width: 900px) {
    width: 100px;
    height: 80px;
  }
  @media (min-width: 1000px) {
    width: 100px;
    height: 80px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: #CECECE;
  text-decoration: none;
`;

export const StyledHR = styled.hr`
  border: 2px solid white;  
  background-color: white;
  border-radius: 2px;
  width: 400px;
  @media (max-width: 567px) {
    width: 250px;
  }
`;

export const StyledLogo = styled.img`
  display: inline;
  width: 60px;
  @media (max-width: 767px) {
    width: 60px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: 10px;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    max-height: 220px;
  }
`;

export const ResponsiveWrapperTitles = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: 10px;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    max-height: 220px;
  }
`;

export const ResponsiveWrapperButtons = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: 10px;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    max-height: 220px;
  }
`;

export const WalletBox = styled.div`
  text-decoration: none;
  border-radius: 10px;
  border: 2px solid white;
  background-color: #3B04A0;
  //padding: 10px;
  font-weight: bold;
  font-size: 15px;
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 0px -2px white;
  -webkit-box-shadow: 0px 4px 0px -2px white;
  -moz-box-shadow: 0px 4px 0px -2px white;
  :hover {
    background-color: grey;
  }
`;

const TextRewards = styled.p`
color: white;
    text-align: center;
    letter-spacing: 5px;
    text-shadow: 0 0 2px black;
    background: #00000075;
    border-radius: 5px;
}
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [walletAddress, setAddress] = useState("Connect Wallet");
  const [claimingNft, setClaimingNft] = useState(false);
  const [StakeNFT, setStakeNFT] = useState(false);
  const [UnStakeNFT, setUnStakeNFT] = useState(false);
  const [ClaimReward, setClaimReward] = useState(false);
  const [CheckReward, setCheckReward] = useState(false);
  const [CheckIDS, setCheckIDS] = useState(false);
  const [Border, setBorder] = useState(null);
  const [feedback, setFeedback] = useState(`Approve The Contract For Staking(You Need to do this once)`);
  const [StakeMSG, setMSG] = useState(`Choose TokenID and Stake`);
  const [UnStakeMSG, setUnMSG] = useState(`Choose TokenID and Unstake`);
  const [HavestMSG, setHarvestMSG] = useState(`Claim Your CHI Tokens`);
  const [RewardMSG, setRewardMSG] = useState(``);
  const [CheckMSG, setCheckMSG] = useState(`Click to Check Your Staked TokenIDs`);
  const [tokens, settokens] = useState([5]);
  const tokensInputRef = useRef();
  const [tokenofowner, settokenofowner] = useState();
  const [StakedNFTS, setStakedNFTS] = useState(0);
  const [tokenids, setTokenids] = useState(0);
  const [TID, setTID] = useState([]);
  const [totalrewards, settotalrewards] = useState(0);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    ENDPOINT: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  ///

const endpoint = CONFIG.ENDPOINT;

const fetchNFTs = async (owner, contractAddress, setNFTs, retryAttempt) => {
  if (retryAttempt === 5) {
    return;
}
if (owner) {
    let data;
    try {
        if (contractAddress) {
            data = await fetch(`${endpoint}/getNFTs?owner=${owner}&contractAddresses%5B%5D=${contractAddress}`).then(data => data.json())
        } else {
            data = await fetch(`${endpoint}/getNFTs?owner=${owner}`).then(data => data.json())
        }
    } catch (e) {
        fetchNFTs(endpoint, owner, contractAddress, setNFTs, retryAttempt+1)
    }

    setNFTs(data.ownedNfts)
    return data
}
}

const [owner, setOwner] = useState(blockchain.account)
const [contractAddress, setContractAddress] = useState(CONFIG.TOKEN)
const [NFTs, setNFTs] = useState("");
  ///


  const Approve = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    setFeedback(`Approving your Staking...`);
    setClaimingNft(true);
    blockchain.smartToken.methods
    .setApprovalForAll(CONFIG.CONTRACT_ADDRESS, true)
    .send({
      to: CONFIG.TOKEN,
      from: blockchain.account,
    })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You can Now Stake Your NFT`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };


  const Stake = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setMSG(`Staking your ${CONFIG.SYMBOL} #${tokens}...`);
    setStakeNFT(true);
    blockchain.smartContract.methods
      .deposit([tokens])
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })

      .once("error", (err) => {
        console.log(err);
        setMSG("Sorry, Something Went Wrong");
        setStakeNFT(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setMSG(
          `You Succesfully Staked Your ${CONFIG.NFT_NAME} #${tokens}`
        );
        setStakeNFT(false);
        dispatch(fetchData(blockchain.account));
      });
  };


  const StakeAll = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setMSG(`Staking your All of Your NFTs...`);
    setStakeNFT(true);
      blockchain.smartToken.methods
      .walletOfOwner(blockchain.account)
      .call()
      .then((receipt) => {
        console.log("Token Ids of User", receipt);
      let TokenidofUser = receipt;

    blockchain.smartContract.methods
      .deposit(TokenidofUser)
      .send({
        gasLimit: String(totalGasLimit * TokenidofUser.length),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setMSG("Sorry, Something Went Wrong");
        setStakeNFT(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setMSG(
          `You Succesfully Staked All of Your NFTs`
        );
        setStakeNFT(false);
        dispatch(fetchData(blockchain.account));
      });
      });
  };

  const UnStake = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setUnMSG(`UnStaking your ${CONFIG.SYMBOL} #${tokens}...`);
    setUnStakeNFT(true);
    blockchain.smartContract.methods
      .withdraw([tokens])
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })

      .once("error", (err) => {
        console.log(err);
        setUnMSG("Sorry, Something went Wrong, check etherscan.");
        setUnStakeNFT(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setUnMSG(
          `You Succesfully UnStaked ${CONFIG.SYMBOL} #${tokens}`
        );
        setUnStakeNFT(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const UnStakeAll = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setUnMSG(`UnStaking All of your NFTs...`);
    setUnStakeNFT(true);
    blockchain.smartContract.methods
      .withdraw(tokenids)
      .send({
        gasLimit: String(totalGasLimit * tokenids.length),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })

      .once("error", (err) => {
        console.log(err);
        setUnMSG("Sorry, Something went Wrong, check etherscan.");
        setUnStakeNFT(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setUnMSG(
          `You Succesfully UnStaked All of Your NFTs.`
        );
        setUnStakeNFT(false);
        dispatch(fetchData(blockchain.account));
      });
  };



  const Harvest = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    setHarvestMSG(`Claiming your Rewards...`);
    setClaimReward(true);
    blockchain.smartContract.methods
    .depositsOf(blockchain.account)
    .call({
      from: blockchain.account,
    })
    .then((receipt) => {
      console.log(receipt);
      setCheckMSG(
        `TokenID #${receipt}`
      );
      let TID = receipt;
      setCheckIDS(false);
      dispatch(fetchData(blockchain.account));
    
    blockchain.smartContract.methods
    .claimRewards(TID)
    .send({
      to: CONFIG.CONTRACT_ADDRESS,
      from: blockchain.account,
    })
      .once("error", (err) => {
        console.log(err);
        setHarvestMSG("Sorry, something went wrong please try again later.");
        setClaimReward(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setHarvestMSG(
          `Rewards Harvested`
        );
        setClaimReward(false);
        dispatch(fetchData(blockchain.account));
      });
    });
  };


  const RewardCheck = () => {
    setRewardMSG(`Checking your ${CONFIG.SYMBOL} #${tokens} Rewards...`);
    setCheckReward(true);
    blockchain.smartContract.methods
      .calculateRewards(blockchain.account, TID)
      .call({
        from: blockchain.account,
      })
      .then((receipt) => {
        console.log(receipt);
        let allrewards = receipt.map(receipt => Web3.utils.fromWei(receipt.toString(), 'ether').substring(0,6)).join(" , ");
        let TTL = Web3.utils.fromWei((receipt[0]).toString(), 'ether').substring(0, 6) * receipt.length;
       console.log("Total Rewards", TTL);
       settotalrewards(TTL);
        setRewardMSG(
          `$ ${totalrewards}`
        );
        setCheckReward(false);
        dispatch(fetchData(blockchain.account));
      });
      

  };

  const CheckNFTs = () => {
    setCheckMSG(`Checking your Staked Tokens...`);
    setCheckIDS(true);
    blockchain.smartContract.methods
      .depositsOf(blockchain.account)
      .call({
        from: blockchain.account,
      })
      .then((receipt) => {
        console.log("stakednfts", receipt);
        setStakedNFTS(receipt.length);
        setTokenids(receipt);
        setTID(receipt.map(Number));
        setCheckMSG(
          `TokenID #${receipt}`
        );
        setCheckIDS(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setAddress(blockchain.account.substring(0,4) + "..." + blockchain.account.substring(38,42));
      {fetchNFTs(blockchain.account, CONFIG.TOKEN, setNFTs)};
      CheckNFTs();
      RewardCheck();
  }};

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
                <ResponsiveWrapperHeader>
                  <s.HeaderDiv>
                  <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
                  <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                          fontSize: 20,
                        }}
                      >
                        BabyDucky Staking Platform
                      </s.TextDescription>
                  </s.HeaderDiv>

<WalletBox
onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
              getData();
            }}>
  {blockchain.account !== "" ? ( 
  <>
  <s.TextSubTitle style={{fontSize: "1rem", color: "white"}}>
    {walletAddress}
    </s.TextSubTitle>
  </>
  ) : null }
</WalletBox>


</ResponsiveWrapperHeader>

                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container
                  flex={2}
                  jc={"center"}
                  ai={"center"}
                  style={{
                    backgroundColor: "var(--accent)",
                    padding: 24,
                    borderRadius: 24,
                    boxShadow: "0px 10px 11px 2px rgba(0,0,0,0.7)",
                  }}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT

                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
        <ResponsiveWrapperTitles>
        <s.TEXTTITLES>
              STAKE
            </s.TEXTTITLES>
            <s.TEXTTITLES>
              UNSTAKE
            </s.TEXTTITLES>
        </ResponsiveWrapperTitles>
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
        <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              boxShadow: "0px 10px 11px 2px rgba(0,0,0,0.7)",
              gap: 20,
            }}
          >
              <s.IMGCT>
              { NFTs ? NFTs.map(NFT => {
                       
                        return (
                           <NftCard id={NFT.metadata.edition}>
                           </NftCard>
                        )
                    }) : 
                    <div>No NFTs found</div>}
              </s.IMGCT>
          </s.Container>
          <s.SpacerLarge />










        

          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              boxShadow: "0px 10px 11px 2px rgba(0,0,0,0.7)",
              gap: 20,
            }}
          >
            {StakedNFTS === 0 ? (
              <>
              <div style={{alignSelf: 'center'}}>No NFTs Staked</div>
              </>
            ) : (
              <>
              <s.IMGCT>
              {
                    tokenids ? tokenids.map(tokenids => {
                       
                        return (
                           <NftCard id={tokenids} ></NftCard>
                        )
                    }) :
                    <div style={{alignSelf: 'center'}}>No NFTs found</div>
                }
              </s.IMGCT>            
              </>
            )}
          </s.Container>



        </ResponsiveWrapper>
        <ResponsiveWrapperButtons>
          <s.BTNDIV>
        <TextRewards
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {StakeMSG}
                    </TextRewards>
                    <InputNumber
                      onChange={ e => {settokens(e)}}
                      ref={tokensInputRef} max={10000} min={1} size={'sm'} placeholder={'TokenID'} />
                    <s.SpacerXSmall/>
                    <div style={{ display: 'flex', gap: 20}}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          Stake();
                          getData();
                          console.log(tokens);
                        }}
                        style={{ width:200 }}
                      >
                        {StakeNFT ? "Staking..." : "Stake"}
                      </StyledButton>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          Approve();
                          getData();
                        }}
                        style={{ width:200 }}
                      >
                        {claimingNft ? "Approving..." : "Approve"}
                      </StyledButton>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          StakeAll();
                          getData();
                        }}
                        style={{ width:200 }}
                      >
                        {claimingNft ? "Staking All..." : "Stake All"}
                      </StyledButton>
                      </div>
                      </s.BTNDIV>


                      <s.BTNDIV>
        <TextRewards
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {UnStakeMSG}
                    </TextRewards>
                    <InputNumber
                      onChange={ e => {settokens(e)}}
                      ref={tokensInputRef} max={10000} min={1} size={'sm'} placeholder={'TokenID'} />
                    <s.SpacerXSmall/>
                    <div style={{ display: 'flex', gap: 20}}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          UnStake();
                          getData();
                        }}
                        style={{ width:200 }}
                      >
                        {StakeNFT ? "UnStaking..." : "UnStake"}
                      </StyledButton>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          Harvest();
                          getData();
                        }}
                        style={{ width:200 }}
                      >
                        {claimingNft ? "Claiming..." : "Claim"}
                      </StyledButton>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          UnStakeAll();
                          getData();
                        }}
                        style={{ width:200 }}
                      >
                        {StakeNFT ? "UnStaking All..." : "UnStake All"}
                      </StyledButton>
                      </div>
                      </s.BTNDIV>
          </ResponsiveWrapperButtons>
          <s.SpacerLarge/>
          <TextRewards>
            $QUACK Earned: <s.SpacerXSmall/>
            {totalrewards}
          </TextRewards>
<s.SpacerSmall/>

                  </>
                )}
      </s.Container>
    </s.Screen>
  );
}

export default App;
