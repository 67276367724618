import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
`;

export const TextSubTitle = styled.p`
  color: black;
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  line-height: 1.6;
`;

export const TEXTTITLES = styled.p`
color: #000000;
    font-size: 2rem;
    line-height: 1.6;
    letter-spacing: 5px;
    @media (max-width: 565px) {
      font-size: 0.75rem;
      letter-spacing: 2px;
    }
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const HeaderDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
align-content: center;
`;

export const IMGCT = styled.div`
display: grid;
grid-template-columns: repeat(5, 1fr);
grid-column-gap: 25px;
grid-row-gap: 30px;
align-items: center;
justify-content: center;
@media (max-width: 565px) {
  grid-template-columns: repeat(2,1fr);
}
`;

export const TOKENSELECT = styled.div`
cursor: pointer;
:hover {
  border: 2px solid black;
}
`

export const BTNDIV = styled.div`
width: 30%;
@media (max-width: 565px) {
  width: 80%
}
`

